html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

@-webkit-keyframes search {
  0% {
    top: 40px;
    left: 60px; }
  25% {
    top: 40px;
    left: calc(100% - 120px); }
  50% {
    top: calc(100% - 100px);
    left: calc(100% - 120px); }
  75% {
    top: calc(100% - 100px);
    left: 60px; }
  100% {
    top: 40px;
    left: 60px; } }

@-moz-keyframes search {
  0% {
    top: 40px;
    left: 60px; }
  25% {
    top: 40px;
    left: calc(100% - 120px); }
  50% {
    top: calc(100% - 100px);
    left: calc(100% - 120px); }
  75% {
    top: calc(100% - 100px);
    left: 60px; }
  100% {
    top: 40px;
    left: 60px; } }

@-o-keyframes search {
  0% {
    top: 40px;
    left: 60px; }
  25% {
    top: 40px;
    left: calc(100% - 120px); }
  50% {
    top: calc(100% - 100px);
    left: calc(100% - 120px); }
  75% {
    top: calc(100% - 100px);
    left: 60px; }
  100% {
    top: 40px;
    left: 60px; } }

@keyframes search {
  0% {
    top: 40px;
    left: 60px; }
  25% {
    top: 40px;
    left: calc(100% - 120px); }
  50% {
    top: calc(100% - 100px);
    left: calc(100% - 120px); }
  75% {
    top: calc(100% - 100px);
    left: 60px; }
  100% {
    top: 40px;
    left: 60px; } }

@media screen {
  .hidden {
    display: none; } }

@media screen {
  html,
  body,
  #root {
    min-height: 100vh;
    max-width: 100vw;
    overflow-x: hidden; } }

@media screen {
  body,
  body > * {
    font-family: "Raleway", sans-serif; } }

@media screen and (min-width: 80em) {
  body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; } }

@media screen and (min-width: 80em) {
  body #root {
    width: 420px;
    height: 100vh;
    margin: 0 auto; } }

@media screen and (min-width: 80em) {
  body #root .desktop_error {
    padding-top: 40px; } }

@media screen and (min-width: 80em) {
  body #root .desktop_error .description {
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 20px;
    padding-top: 20px;
    width: 100%; } }

@media screen and (min-width: 80em) {
  body.mobile-only {
    display: flex;
    flex-direction: column;
    justify-content: center; } }

@media screen and (min-width: 80em) {
  body.mobile-only #root {
    width: 320px;
    height: 480px;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 0;
    border: solid 1px #eeeeee; } }

@media screen and (min-width: 80em) {
  body.mobile-only #root .wrapper {
    width: 100%;
    max-width: none;
    box-sizing: border-box; } }

@media screen {
  .wrapper {
    padding: 0 20px;
    max-width: calc(100vw - ( 2 * 20px )); } }

@media screen {
  .row {
    display: flex;
    flex-direction: row; } }

@media screen {
  .column {
    display: flex;
    flex-direction: column; } }

@media screen {
  .btn {
    color: #ffffff;
    background-color: #703dba;
    font-size: 16px;
    line-height: 48px;
    font-weight: 700;
    text-align: center;
    margin: 0 0 20px 0;
    display: block; } }

@media screen {
  .btn.outline {
    color: #703dba;
    background-color: #ffffff;
    -webkit-box-shadow: inset 0px 0px 0px 2px #703dba;
    -moz-box-shadow: inset 0px 0px 0px 2px #703dba;
    -ms-box-shadow: inset 0px 0px 0px 2px #703dba;
    box-shadow: inset 0px 0px 0px 2px #703dba; } }

@media screen {
  header {
    padding: 20px 0; } }

@media screen {
  .logo {
    margin: 0 0 0 auto;
    width: 100px;
    height: 48px;
    background-image: url("../assets/images/ds-logo.png");
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: contain; } }

@media screen {
  .description_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 0 20px 0; } }

@media screen {
  .description_container .description {
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.5;
    display: block; } }

@media screen {
  .instructions ul {
    padding: 0 0 0 20px;
    margin: 0 0 20px; } }

@media screen {
  .instructions ul li {
    margin: 0 0 10px 0;
    padding: 0 0 10px 0;
    border-bottom: solid 1px #eeeeee;
    list-style: square;
    font-size: 16px;
    line-height: 24px; } }

@media screen {
  .instructions ul li:last-child {
    margin: 0;
    border-bottom: none; } }

@media screen and (orientation: landscape) and (max-width: 48em) {
  .example_list {
    display: flex;
    flex-direction: row; } }

@media screen {
  .example_list .example {
    display: flex;
    flex-direction: row;
    margin: 0 0 20px 0; } }

@media screen and (orientation: landscape) and (max-width: 48em) {
  .example_list .example {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0; } }

@media screen {
  .example_list .example .example_text {
    padding: 0 0 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center; } }

@media screen {
  .icon {
    flex: none;
    width: 32px;
    height: 32px;
    margin: 0 20px 0 0; } }

@media screen and (orientation: landscape) and (max-width: 48em) {
  .capture_photo .capture_group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; } }

@media screen and (orientation: landscape) and (max-width: 48em) {
  .capture_photo .capture_group .capture_container {
    width: 60%;
    box-sizing: border-box; } }

@media screen {
  .capture_photo .capture_group .capture_container .capture {
    display: block;
    width: 100%;
    height: 100%;
    margin: 0 0 20px 0; } }

@media screen and (orientation: landscape) and (max-width: 48em) {
  .capture_photo .capture_group .capture_container .capture {
    margin: 0; } }

@media screen and (orientation: landscape) and (max-width: 48em) {
  .capture_photo .capture_group .capture_controls {
    width: 40%;
    box-sizing: border-box;
    justify-content: center; } }

@media screen and (orientation: landscape) and (max-width: 48em) {
  .analyzing .analyzing_group .analyzing_container {
    width: 60%;
    box-sizing: border-box; } }

@media screen {
  .analyzing .analyzing_group .analyzing_container .analyzing_animation_zone {
    position: relative; } }

@media screen {
  .analyzing .analyzing_group .analyzing_container .analyzing_animation_zone .id_background {
    display: block;
    width: 100%;
    height: auto; } }

@media screen {
  .analyzing .analyzing_group .analyzing_container .analyzing_animation_zone::before {
    content: '';
    display: block;
    width: 64px;
    height: 64px;
    background-image: url("../assets/images/finder@2x.png");
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: contain;
    position: absolute;
    -webkit-animation: search 4s ease-in-out infinite forwards 0s;
    -moz-animation: search 4s ease-in-out infinite forwards 0s;
    -o-animation: search 4s ease-in-out infinite forwards 0s;
    animation: search 4s ease-in-out infinite forwards 0s; } }

@media screen {
  .results .results_id_sig {
    justify-content: space-between;
    margin: 0 0 20px 0; } }

@media screen {
  .results .results_id_sig .results_id {
    flex: none;
    width: calc(30% - 10px);
    height: 128px;
    padding: 0 10px 0 0; } }

@media screen {
  .results .results_id_sig .results_id img {
    display: block;
    height: 100%;
    width: auto;
    margin: 0; } }

@media screen {
  .results .results_id_sig .results_sig {
    flex: none;
    width: calc(70% - 10px);
    max-width: 240px;
    padding: 0 0 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: center; } }

@media screen {
  .results .results_id_sig .results_sig img {
    display: block;
    width: 100%;
    height: auto; } }

@media screen {
  .results .results_id_sig .results_medcard {
    width: calc(50% - 10px);
    height: auto; } }

@media screen {
  .results .results_id_sig .results_medcard img {
    display: block;
    width: 100%;
    height: auto; } }

@media screen {
  .results .results_data {
    margin: 0 0 20px 0; } }

@media screen {
  .results .results_data .row {
    padding: 0 0 10px 0;
    margin: 0 0 10px 0;
    border-bottom: solid 1px #eeeeee; } }

@media screen {
  .results .results_data .row:last-of-type {
    border-bottom: none;
    margin: 0; } }

@media screen {
  .results .results_data .row .type,
  .results .results_data .row .data {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0; } }

@media screen {
  .results .results_data .row .type {
    font-size: 14px;
    line-height: 24px;
    font-weight: 700; } }

@media screen {
  .results .results_data .row .data {
    font-size: 14px;
    line-height: 24px; } }
